<template>
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-indigo">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title">Lista</h4>
      </md-card-header>

      <md-card-content>
        <md-table
          :value="table"
          :md-sort.sync="sortation.field"
          :md-sort-order.sync="sortation.order"
          class="paginated-table table-striped table-hover"
        >
          <md-table-toolbar>
            <md-field>
              <label>Per page</label>
              <md-select v-model="pagination.perPage" name="pages">
                <md-option
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                  {{ item }}
                </md-option>
              </md-select>
            </md-field>
            <md-button class="md-info" @click="geoReference()"
              >Referenciar selecionados</md-button
            >

            <md-button class="md-danger" @click="selectAll"
              >Selecionar Tudo</md-button
            >
          </md-table-toolbar>

          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="CNES" md-sort-by="cnes_code">{{
              item.cnes_code
            }}</md-table-cell>
            <md-table-cell md-label="Nome" md-sort-by="company_name">{{
              item.company_name
            }}</md-table-cell>
            <md-table-cell
              md-label="Nome Fantasia"
              md-sort-by="alias_company_name"
              >{{ item.alias_company_name }}</md-table-cell
            >
            <md-table-cell md-label="Estado" md-sort-by="state">{{
              item.state
            }}</md-table-cell>
            <md-table-cell md-label="Cidade" md-sort-by="city">{{
              item.city
            }}</md-table-cell>
            <md-table-cell md-label="Latitude" md-sort-by="latitude">{{
              item.latitude
            }}</md-table-cell>
            <md-table-cell md-label="Longitude" md-sort-by="longitude">{{
              item.longitude
            }}</md-table-cell>

            <md-table-cell md-label="Actions">
              <md-switch
                v-model="healthUnitsSelected"
                :value="item.id"
              ></md-switch>

              <md-button
                class="md-icon-button md-raised md-round md-info"
                @click="geoReference()"
                style="margin: .2rem;"
              >
                <md-icon>travel_explore</md-icon>
              </md-button>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>

      <md-card-actions md-alignment="space-between">
        <div class="">
          <p class="card-category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          </p>
        </div>
        <pagination
          class="pagination-no-border pagination-success"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
          @input="setPage"
        />
      </md-card-actions>
    </md-card>
  </div>
</template>
<script>
import Vue from "vue";
import Pagination from "@/components/Pagination";

export default {
  components: {
    Pagination
  },
  props: {
    change: Boolean,
    source: null,
    type: null,
    year: null
  },
  watch: {
    change: {
      handler: function() {
        this.getList();
      }
    }
  },
  data() {
    return {
      load: false,
      selectedEmployee: null,
      healthUnits: [],
      healthUnitsSelected: [], //
      healthUnitName: null,
      healthUnit: {
        alias_company_name: null,
        company_name: null,
        cnes_code: null,
        city: {
          name: null
        }
      },
      selected: [],
      table: [],
      query: null,

      sortation: {
        field: "id",
        order: "asc"
      },

      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 100, 150, 250, 500]
      },

      total: 0
    };
  },
  computed: {
    sort() {
      if (this.sortation.order === "desc") {
        return `-${this.sortation.field}`;
      }

      return this.sortation.field;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    }
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    async editItem(item) {
      try {
        let result = await Vue.$http.get(
          `v1/health_unit/?cnes_code=${item.cnes_code}`
        );
        this.healthUnit = result.data;
        this.occupationOfHealthUnit = item;
        this.$emit("editItem", {
          healthUnit: this.healthUnit,
          occupationOfHealthUnit: this.occupationOfHealthUnit
        });
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
      }
    },
    getClass: ({ id }) => ({
      "table-success": id === 1,
      "table-info": id === 3,
      "table-danger": id === 5,
      "table-warning": id === 7
    }),
    setPage(page) {
      this.pagination.currentPage = page;
      this.getList();
    },
    async getList() {
      try {
        let result = await Vue.$http.get(
          `v1/health_unit?per_page=${this.pagination.perPage}&page=${this.pagination.currentPage}`
        );
        let dataSets = result.data;
        this.table = dataSets.data;
        this.total = result.data.total;
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
      }
    },
    customSort() {
      return false;
    },
    selectAll() {
      this.table.forEach(item => {
        this.healthUnitsSelected.push(item.id);
      });
    },
    async geoReference() {
      try {
        await Vue.$http.patch(`v1/health_unit?action=geo_reference`, {
          ids: this.healthUnitsSelected
        });
        this.$store.dispatch("alerts/success", "Dados atualizado");
      } catch (error) {
        this.load = false;
        console.log(error);
        return await error.response;
      }

      this.healthUnitsSelected = [];
      await this.getList();
    }
  }
};
</script>
<style>
.table-transparent {
  background-color: transparent !important;
}

.mt-0 {
  margin-top: 0 !important;
}
</style>
