<template>
  <div>
    <div class="md-layout">
      <component
        v-bind:is="formComponent"
        :source.sync="source"
        :type.sync="type"
        :year.sync="year"
        @addItem="addItem"
        :itemHealthUnit.sync="healthUnit"
        :item="occupationOfHealthUnit"
      ></component>
    </div>
    <div class="md-layout">
      <component
        v-bind:is="listComponent"
        :source.sync="source"
        :type.sync="type"
        :year.sync="year"
        :change.sync="change"
        @editItem="editItem"
      ></component>
    </div>
  </div>
</template>
<script>
import OccupationOfHealthUnitsForm from "./components/OccupationOfHealthUnitsForm";
import OccupationOfHealthUnitsList from "./components/OccupationOfHealthUnitsList";
import HealthUnitsList from "./components/HealthUnitsList";

export default {
  components: {
    OccupationOfHealthUnitsForm,
    OccupationOfHealthUnitsList,
    HealthUnitsList
  },
  data() {
    return {
      healthUnit: null,
      occupationOfHealthUnit: null,
      change: true,
      type: null,
      source: null,
      year: null,
      formComponent: null,
      listComponent: null
    };
  },
  created() {
    this.source = this.$route.params.source;
    this.type = this.$route.params.type;
    this.year = this.$route.params.year;
    if (this.type == "occupation_of_health_units") {
      this.formComponent = "OccupationOfHealthUnitsForm";
      this.listComponent = "OccupationOfHealthUnitsList";
    } else if (this.type == "health_units") {
      this.listComponent = "HealthUnitsList";
    }
  },
  mounted() {},
  methods: {
    editItem(item) {
      this.healthUnit = item.healthUnit;
      this.occupationOfHealthUnit = item.occupationOfHealthUnit;
    },
    addItem() {
      this.change = !this.change;
      console.log(this.change);
    }
  }
};
</script>
<style>
.table-transparent {
  background-color: transparent !important;
}

.mt-0 {
  margin-top: 0 !important;
}
</style>
