<template>
  <div class="md-layout-item md-size-100 md-layout">
    <div class="md-layout-item md-size-100" v-show="load" style="padding: 0">
      <md-progress-bar
        class="md-accent"
        md-mode="indeterminate"
      ></md-progress-bar>
    </div>
    <div class="md-layout-item md-size-100" v-show="!load" style="padding: 0">
      <ValidationObserver
        ref="form"
        class="md-layout md-alignment-center-center"
      >
        <form
          @submit.prevent="onSubmit"
          class="md-layout-item md-size-100"
          style="padding: 0"
        >
          <md-card>
            <md-card-header class="md-card-header-icon md-card-header-danger">
              <div class="card-icon">
                <md-icon>backup</md-icon>
              </div>
              <h4 class="title">Ocupação hospitalar</h4>
            </md-card-header>

            <md-card-content>
              <md-toolbar class="md-primary">
                <div class="md-toolbar-row">
                  <div class="md-toolbar-section-start"></div>

                  <md-autocomplete
                    class="search"
                    v-model="healthUnitName"
                    :md-options="healthUnits"
                    @md-changed="getHealthUnits"
                    @md-selected="selectedHealthUnit"
                    md-layout="box"
                  >
                    <label>Buscar unidade de saúde...</label>

                    <template
                      slot="md-autocomplete-item"
                      slot-scope="{ item, keyword }"
                    >
                      <md-highlight-text :md-term="keyword">
                        Nome: {{ item.alias_company_name }} CNES:
                        {{ item.cnes_code }} Cidade: {{ item.city.name }}
                      </md-highlight-text>
                    </template>

                    <template slot="md-autocomplete-empty">
                      Unidade não encontrada
                    </template>
                  </md-autocomplete>

                  <div class="md-toolbar-section-end"></div>
                </div>
              </md-toolbar>

              <div class="md-layout">
                <div
                  class="md-layout-item md-size-25 md-small-size-100 mr-auto"
                >
                  <ValidationProvider
                    name="Nome Fantasia"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <md-field class="md-form-group md-invalid">
                      <md-icon>vpn_key</md-icon>
                      <label>Nome Fantasia:</label>

                      <md-input
                        v-model="healthUnit.alias_company_name"
                        disabled
                      />
                      <validation-error :errors="errors" />
                    </md-field>
                  </ValidationProvider>
                </div>

                <div
                  class="md-layout-item md-size-25 md-small-size-100 mr-auto"
                >
                  <ValidationProvider
                    name="Nome"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <md-field class="md-form-group md-invalid">
                      <md-icon>vpn_key</md-icon>
                      <label>Nome:</label>

                      <md-input v-model="healthUnit.company_name" disabled />
                      <validation-error :errors="errors" />
                    </md-field>
                  </ValidationProvider>
                </div>

                <div
                  class="md-layout-item md-size-25 md-small-size-100 mr-auto"
                >
                  <ValidationProvider
                    name="CNES"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <md-field class="md-form-group md-invalid">
                      <md-icon>vpn_key</md-icon>
                      <label>CNES:</label>

                      <md-input v-model="healthUnit.cnes_code" disabled />
                      <validation-error :errors="errors" />
                    </md-field>
                  </ValidationProvider>
                </div>
                <div
                  class="md-layout-item md-size-25  md-small-size-100 mr-auto"
                >
                  <ValidationProvider
                    name="Cidade"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <md-field class="md-form-group md-invalid">
                      <md-icon>vpn_key</md-icon>
                      <label>Cidade:</label>

                      <md-input v-model="healthUnit.city.name" disabled />
                      <validation-error :errors="errors" />
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-25 md-small-size-100">
                  <ValidationProvider
                    name="Data"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <md-datepicker v-model="occupationOfHealthUnit.date" />

                    <validation-error :errors="errors" />
                  </ValidationProvider>
                </div>
                <div class="md-layout-item md-size-25 md-small-size-100">
                  <ValidationProvider
                    name="Altas"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <md-field class="md-form-group md-invalid">
                      <md-icon>publish</md-icon>
                      <label>Altas</label>

                      <md-input
                        v-model="occupationOfHealthUnit.highs"
                        type="number"
                      />
                      <validation-error :errors="errors" />
                    </md-field>
                  </ValidationProvider>
                </div>
                <div class="md-layout-item md-size-25 md-small-size-100">
                  <ValidationProvider
                    name="Óbitos"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <md-field class="md-form-group md-invalid">
                      <md-icon>local_hospital</md-icon>
                      <label>Óbitos</label>

                      <md-input
                        v-model="occupationOfHealthUnit.deaths"
                        type="number"
                      />
                      <validation-error :errors="errors" />
                    </md-field>
                  </ValidationProvider>
                </div>
                <div class="md-layout-item md-size-25 md-small-size-100">
                  <ValidationProvider
                    name="Transferência"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <md-field class="md-form-group md-invalid">
                      <md-icon>sync_alt</md-icon>
                      <label>Transferência</label>

                      <md-input
                        v-model="occupationOfHealthUnit.transfers"
                        type="number"
                      />
                      <validation-error :errors="errors" />
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <ValidationProvider
                    name="Leitos"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <md-field class="md-form-group md-invalid">
                      <md-icon><i class="fas fa-bed"></i></md-icon>
                      <label>Leitos</label>

                      <md-input
                        v-model="occupationOfHealthUnit.beds"
                        type="number"
                      />
                      <validation-error :errors="errors" />
                    </md-field>
                  </ValidationProvider>
                </div>
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <ValidationProvider
                    name="Leitos ocupados"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <md-field class="md-form-group md-invalid">
                      <md-icon><i class="fas fa-bed"></i></md-icon>
                      <label>Leitos ocupados</label>

                      <md-input
                        v-model="occupationOfHealthUnit.occupied_beds"
                        type="number"
                      />
                      <validation-error :errors="errors" />
                    </md-field>
                  </ValidationProvider>
                </div>
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <ValidationProvider
                    name="Leitos COVID"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <md-field class="md-form-group md-invalid">
                      <md-icon><i class="fas fa-bed"></i></md-icon>
                      <label>Leitos COVID</label>

                      <md-input
                        v-model="occupationOfHealthUnit.occupied_covids"
                        type="number"
                      />
                      <validation-error :errors="errors" />
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <ValidationProvider
                    name="Leitos de UTI"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <md-field class="md-form-group md-invalid">
                      <md-icon><i class="fas fa-bed"></i></md-icon>
                      <label>Leitos de UTI</label>

                      <md-input
                        v-model="occupationOfHealthUnit.uti_beds"
                        type="number"
                      />
                      <validation-error :errors="errors" />
                    </md-field>
                  </ValidationProvider>
                </div>
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <ValidationProvider
                    name="Leitos ocupados de UTI"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <md-field class="md-form-group md-invalid">
                      <md-icon><i class="fas fa-bed"></i></md-icon>
                      <label>Leitos ocupados de UTI</label>

                      <md-input
                        v-model="occupationOfHealthUnit.uti_occupied_beds"
                        type="number"
                      />
                      <validation-error :errors="errors" />
                    </md-field>
                  </ValidationProvider>
                </div>
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <ValidationProvider
                    name="Leitos de UTI COVID"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <md-field class="md-form-group md-invalid">
                      <md-icon><i class="fas fa-bed"></i></md-icon>
                      <label>Leitos de UTI COVID</label>

                      <md-input
                        v-model="occupationOfHealthUnit.uti_occupied_covids"
                        type="number"
                      />
                      <validation-error :errors="errors" />
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <ValidationProvider
                    name="Leitos"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <md-field class="md-form-group md-invalid">
                      <md-icon><i class="fas fa-bed"></i></md-icon>
                      <label>Leitos de estabilização</label>

                      <md-input
                        v-model="occupationOfHealthUnit.stabilization_beds"
                        type="number"
                      />
                      <validation-error :errors="errors" />
                    </md-field>
                  </ValidationProvider>
                </div>
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <ValidationProvider
                    name="Leitos ocupados de estabilização
"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <md-field class="md-form-group md-invalid">
                      <md-icon><i class="fas fa-bed"></i></md-icon>
                      <label>Leitos ocupados de estabilização</label>

                      <md-input
                        v-model="
                          occupationOfHealthUnit.stabilization_occupied_beds
                        "
                        type="number"
                      />
                      <validation-error :errors="errors" />
                    </md-field>
                  </ValidationProvider>
                </div>
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <ValidationProvider
                    name="Leitos de estabilização COVID"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <md-field class="md-form-group md-invalid">
                      <md-icon><i class="fas fa-bed"></i></md-icon>
                      <label>Leitos de estabilização COVID</label>

                      <md-input
                        v-model="
                          occupationOfHealthUnit.stabilization_occupied_covids
                        "
                        type="number"
                      />
                      <validation-error :errors="errors" />
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>
            </md-card-content>

            <md-card-actions>
              <md-card-actions>
                <md-button @click="clear" class="md-success">
                  Novo
                </md-button>
                <md-button type="submit" class="md-danger">
                  Salvar
                </md-button>
              </md-card-actions>
            </md-card-actions>
          </md-card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { ValidationError } from "@/components";

export default {
  components: {
    ValidationError
  },
  props: {
    item: null,
    itemHealthUnit: null,
    source: null,
    type: null,
    year: null
  },
  data() {
    return {
      load: false,
      occupationOfHealthUnit: {
        id: null,
        date: null,
        cnes_code: null,
        beds: 0,
        occupied_beds: 0,
        occupied_covids: 0,
        uti_beds: 0,
        uti_occupied_beds: 0,
        uti_occupied_covids: 0,
        stabilization_beds: 0,
        stabilization_occupied_beds: 0,
        stabilization_occupied_covids: 0,
        highs: 0,
        deaths: 0,
        transfers: 0
      },
      selectedEmployee: null,
      healthUnits: [],
      healthUnitName: null,
      healthUnit: {
        alias_company_name: null,
        company_name: null,
        cnes_code: null,
        city: {
          name: null
        }
      }
    };
  },
  computed: {},
  watch: {
    item: function(item) {
      if (item != null) {
        this.occupationOfHealthUnit = item;
      }
    },
    itemHealthUnit: function(itemHealthUnit) {
      if (itemHealthUnit != null) {
        this.healthUnit = itemHealthUnit;
      }
    }
  },

  created() {},
  mounted() {},
  methods: {
    async getHealthUnits(keyword) {
      try {
        if (keyword.length < 3) {
          this.healthUnits = [];
          return true;
        }

        let result = await Vue.$http.get(`v1/health_unit/?keyword=${keyword}`);
        this.healthUnits = result.data;
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
      }
    },
    selectedHealthUnit(item) {
      this.healthUnit = item;
      this.healthUnitName = this.healthUnit.alias_company_name;
    },
    async create() {
      try {
        this.occupationOfHealthUnit.cnes_code = this.healthUnit.cnes_code;
        await Vue.$http.post(
          `v1/dataset/${this.source}/${this.type}?type=single`,
          this.occupationOfHealthUnit
        );
        await this.$store.dispatch(
          "alerts/success",
          "Successfully registered."
        );
        this.$emit("addItem");
      } catch (e) {
        console.log(e);
        await this.$store.dispatch(
          "alerts/error",
          "Oops, something went wrong"
        );
        this.setApiValidation(e.response.data.errors);
      }
    },
    async update() {
      try {
        this.occupationOfHealthUnit.cnes_code = this.healthUnit.cnes_code;
        await Vue.$http.put(
          `v1/dataset/${this.source}/${this.type}/${this.occupationOfHealthUnit.id}?type=single`,
          this.occupationOfHealthUnit
        );

        await this.$store.dispatch(
          "alerts/success",
          "Successfully registered."
        );
        this.$emit("addItem");
      } catch (e) {
        console.log(e);
        await this.$store.dispatch(
          "alerts/error",
          "Oops, something went wrong"
        );
        this.setApiValidation(e.response.data.errors);
      }
    },
    async onSubmit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          let values = Object.values(this.$refs.form.errors);
          values.forEach(value => {
            value.forEach(message => {
              this.alert(message);
            });
          });
          return;
        }

        if (this.occupationOfHealthUnit.id == null) {
          this.create();
        } else {
          this.update();
        }

        this.$nextTick(() => {
          //this.$refs.form.reset();
        });
      });
    },
    clear() {
      this.occupationOfHealthUnit = {
        id: null,
        date: null,
        cnes_code: null,
        beds: 0,
        occupied_beds: 0,
        occupied_covids: 0,
        uti_beds: 0,
        uti_occupied_beds: 0,
        uti_occupied_covids: 0,
        stabilization_beds: 0,
        stabilization_occupied_beds: 0,
        stabilization_occupied_covids: 0,
        highs: 0,
        deaths: 0,
        transfers: 0
      };
      this.healthUnit = {
        alias_company_name: null,
        company_name: null,
        cnes_code: null,
        city: { name: null }
      };
    }
  }
};
</script>
<style>
.table-transparent {
  background-color: transparent !important;
}

.mt-0 {
  margin-top: 0 !important;
}
</style>
